import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";

import { useAccessToken } from "./use-access-token";
import { callLcosService } from "../data/lcosServices";

export type Permission = string;

export interface Role {
  name: string;
  isImmutable: boolean;
  value: string;
  defaultPermissions: string[];
}
export interface ReferenceRecord {
  value: string | number;
  name: string;
  isActive?: boolean;
  internalCode?: string;
}

export type DynamicsRecord<T> = T & {
  externalId: string;
};

export type SystemPermission = ReferenceRecord;

export const referenceRecordsToLookup = (referenceRecords?: ReferenceRecord[]) => {
  return (
    referenceRecords?.reduce((acc: Record<string, string>, current) => {
      acc[current.value] = current.name;
      return { ...acc };
    }, {}) ?? {}
  );
};

export const referenceRecordToCodeLookup = (referenceRecords?: ReferenceRecord[]) => {
  return (
    referenceRecords?.reduce((acc: Record<string, ReferenceRecord>, current) => {
      if (current.internalCode) {
        acc[current.internalCode] = current;
      }
      return { ...acc };
    }, {}) ?? {}
  );
};

export const EXTENDED_CACHE_TIME = 60 * 60 * 1000; // one hour

export interface StaffPermission {
  displayName: string;
  permissions: ReferenceRecord[];
  groups?: StaffPermission[];
}
export interface ReferenceDataCollection {
  deliveryMethods: ReferenceRecord[];
  permissions: StaffPermission[];
  positions: ReferenceRecord[];
  roles: Role[];
  sessionRatios: ReferenceRecord[];
  socialMediaTypes: [string, string][];
  staffMemberJobTypes: ReferenceRecord[];
  staffMemberSeparationReasons: ReferenceRecord[];
  staffMemberStatuses: ReferenceRecord[];
  subjects: string[];
  weekdays: ReferenceRecord[];
}

export const useReferenceData = () => {
  const { getToken } = useAccessToken();

  const getReferenceData = async () => {
    return getReference(await getToken());
  };

  const refDataQuery = useQuery({
    queryKey: ["referenceData"],
    queryFn: getReferenceData,
    cacheTime: EXTENDED_CACHE_TIME,
    staleTime: EXTENDED_CACHE_TIME
  });

  const immutableRoles = useMemo(() => {
    return refDataQuery.data?.roles?.filter(r => r.isImmutable) ?? [];
  }, [refDataQuery.data?.roles]);

  const mutableRoles = useMemo(() => {
    return (
      refDataQuery.data?.roles
        ?.filter(r => !r.isImmutable)
        ?.sort(left => (left.name === "Center Owner" ? /* istanbul ignore next */ -1 : 1)) ?? []
    );
  }, [refDataQuery.data?.roles]);

  // no longer sorting here because BE will be delivering them in the order desired by HLC
  const positions = useMemo(() => {
    return refDataQuery.data?.positions ?? [];
  }, [refDataQuery.data?.positions]);

  const weekdayLookup = useMemo(() => {
    return referenceRecordsToLookup(refDataQuery.data?.weekdays);
  }, [refDataQuery.data?.weekdays]);

  const jobTypes = useMemo(
    () => refDataQuery.data?.staffMemberJobTypes.map(jobType => ({ id: jobType.value, ...jobType })),
    [refDataQuery.data?.staffMemberJobTypes]
  );

  const separationReasons = useMemo(
    () =>
      refDataQuery.data?.staffMemberSeparationReasons.map(separationReason => ({
        id: separationReason.value,
        ...separationReason
      })),
    [refDataQuery.data?.staffMemberSeparationReasons]
  );

  const positionsLookup = useMemo(() => referenceRecordsToLookup(positions), [positions]);

  const deliveryMethods = useMemo(() => {
    return refDataQuery.data?.deliveryMethods ?? [];
  }, [refDataQuery.data?.deliveryMethods]);

  const sessionRatios = useMemo(() => {
    return refDataQuery.data?.sessionRatios ?? [];
  }, [refDataQuery.data?.sessionRatios]);

  const sessionRatioLookup = useMemo(() => {
    return referenceRecordsToLookup(sessionRatios);
  }, [sessionRatios]);

  const defaultPermissionsLookup = useMemo(() => {
    return (refDataQuery.data?.roles ?? []).reduce(
      (acc, role) => ({ ...acc, [role.value]: role.defaultPermissions }),
      {} as Record<string, string[]>
    );
  }, [refDataQuery.data?.roles]);

  return {
    referenceData: refDataQuery.data,
    isLoading: refDataQuery.isLoading,
    immutableRoles,
    mutableRoles,
    positions,
    positionsLookup,
    weekdayLookup,
    jobTypes,
    separationReasons,
    deliveryMethods,
    sessionRatios,
    sessionRatioLookup,
    defaultPermissionsLookup,
    socialMediaTypes: refDataQuery.data?.socialMediaTypes
  };
};

export const getReference = (accessToken: string) => {
  return callLcosService<ReferenceDataCollection>(accessToken, `/api/data/constants`);
};
