import React, { useMemo } from "react";

import { useAccessControl } from "../../app/use-access-control";
import { CODES } from "../../data/dynamics-codes";
import { useCenterConstants } from "../../hooks/use-center";
import type { ReferenceRecord } from "../../hooks/use-reference-data";
import { useStaffAppointmentTypes, type Availability } from "../../hooks/use-staff/use-staff-appointment-types";
import { GenericMultiSelectDropdown } from "../search-combobox/generic-multi-select-dropdown";

interface AppointmentLocationSelectProps {
  staffMemberId: string;
  item: Availability;
  appointmentTypeId: string;
}

export const AppointmentLocationSelect: React.FC<AppointmentLocationSelectProps> = ({
  staffMemberId,
  item,
  appointmentTypeId
}) => {
  const { activeCenterId } = useAccessControl();
  const centerConstantsQuery = useCenterConstants(activeCenterId);

  const locationOptions = useMemo(() => {
    return (
      centerConstantsQuery.activeConstants?.activeAppointmentLocations?.filter(
        ({ internalCode }) =>
          internalCode === CODES.LOCATIONS.IN_CENTER || internalCode === CODES.LOCATIONS.ONLINE_VIRTUAL
      ) ?? []
    );
  }, [centerConstantsQuery.activeConstants?.appointmentLocations]);

  const { updateAvailabilityForAppointmentTypeMutator } = useStaffAppointmentTypes(activeCenterId, staffMemberId);

  const handleSelectClose = (appointmentTypeId: string, availability: Availability, items: ReferenceRecord[]) => {
    // Disable appointment availability if user tries to save w/no locations
    const isAvailable = availability.isAvailable && items.length > 0;
    updateAvailabilityForAppointmentTypeMutator.mutate({
      appointmentTypeId,
      updatedAvailability: {
        serviceId: availability.serviceId,
        isAvailable: isAvailable,
        locations: items.map(item => item.value as string)
      },
      skipQueryUpdate: isAvailable
    });
  };

  return (
    <GenericMultiSelectDropdown
      items={locationOptions}
      getLabel={item => item?.name || ""}
      selectedItems={item.locations}
      getValue={item => (item?.value as string) || ""}
      toggleLabel="Location"
      onBlur={items => {
        handleSelectClose(appointmentTypeId, item, items);
      }}
      id={`location-${appointmentTypeId}-${item.serviceId}`}
    />
  );
};
