import { useQuery } from "@tanstack/react-query";

import { callLcosService } from "../../data/lcosServices";
import { familyQueryKeys } from "./query-keys";
import { type FamilyConstants } from "./types";
import { useAccessToken } from "../use-access-token";
import { EXTENDED_CACHE_TIME, referenceRecordsToLookup } from "../use-reference-data";

export const useFamilyConstants = () => {
  const { getToken } = useAccessToken();

  const query = useQuery({
    queryKey: familyQueryKeys.constants(),
    queryFn: async () => getFamiliesConstants(await getToken()),
    cacheTime: EXTENDED_CACHE_TIME,
    staleTime: EXTENDED_CACHE_TIME,
    select: data => {
      const studentRelationshipValue = data.relationships.find(
        ({ internalCode }) => internalCode?.toLowerCase() === "student"
      )?.value;
      // TODO: Add invariant check when we're getting a "Student" option from the backend, to fail fast
      // invariant(typeof selfRelationshipValue === "number", "Student relationship not found in family constants");

      return {
        familyConstants: data,
        familyRoleLookup: referenceRecordsToLookup(data.roles),
        familyRelationshipLookup: referenceRecordsToLookup(data.relationships),
        roles: data.roles || [],
        activeRoles: data.roles?.filter(({ isActive }) => isActive) || [],
        relationships: data.relationships || [],
        activeRelationships: data.relationships?.filter(({ isActive }) => isActive) || [],
        titles: data.titles || [],
        activeTitles: data.titles?.filter(({ isActive }) => isActive) || [],
        genders: data.genders || [],
        activeGenders: data.genders?.filter(({ isActive }) => isActive) || [],
        activeGrades: data.grades?.filter(({ isActive }) => isActive) || [],
        activePreferredContactMethods: data.preferredContactMethods?.filter(({ isActive }) => isActive) || [],
        gradeLookup: referenceRecordsToLookup(data.grades),
        genderLookup: referenceRecordsToLookup(data.genders),
        // TODO: clean these up
        EMERGENCY_CONTACT_VALUE:
          (data.relationships?.find(relationship => relationship.name === "Emergency Contact")?.value as string) ?? "",
        PARENT_GUARDIAN_VALUE:
          (data.relationships?.find(relationship => relationship.name === "Parent/Guardian")?.value as string) ?? "",
        OTHER_VALUE: (data.relationships?.find(({ name }) => name === "Other")?.value as number) ?? 0,
        STUDENT_VALUE: studentRelationshipValue
      };
    }
  });

  const defaults = {
    familyConstants: {} as FamilyConstants,
    familyRoleLookup: {} as Record<string, string>,
    familyRelationshipLookup: {} as Record<string, string>,
    roles: [],
    relationships: [],
    activeRelationships: [],
    titles: [],
    genders: [],
    activeGrades: [],
    gradeLookup: {} as Record<string, string>,
    genderLookup: {} as Record<string, string>,
    EMERGENCY_CONTACT_VALUE: "",
    PARENT_GUARDIAN_VALUE: "",
    STUDENT_VALUE: ""
  };

  return {
    ...defaults,
    ...query.data,
    isLoading: query.isLoading
  };
};

const getFamiliesConstants = (accessToken: string) => {
  return callLcosService<FamilyConstants>(accessToken, `/api/families/constants`, "GET");
};
